import { AxiosInstance } from 'axios'

import { ColorScheme } from '@/service'

export interface Step {
  title: string
  content: [
    {
      question: string
      answer: string
    }
  ]
}

export type TagsTypes =
  | 'EV_CHARGERS'
  | 'NAVIPAY'
  | 'AUTOPAY'
  | 'PARK_AND_FLY'
  | 'SCOOTERS'
  | 'VALET'

export interface Parking {
  uuid: string
  shortDisplayName: string
  urlSlug: string
  mainPictureUrl: string
  address: string
  lat: number
  lon: number
  licensePlateFormat: string
  reservationAvailable: boolean
  subscriptionAvailable: boolean
  additionalInfo?: 'NO_SPOTS' | 'COMING_SOON' | 'UNAVAILABLE' | null
  translationKeys: {
    address: string
    parkingLotName: string
  }
  infoTags: TagsTypes[] | null
  cityUrlSlug: string
  cityUrlSlugs: {
    pl: string
    en: string
    de: string
    ar: string
  }
  price: {
    plans: Plan[]
    pricePer24Hours: Price
    pricePerHour: Price
  }
}

export interface ThankYouContent {
  title: string
  description: string
}

export interface ThankYouNotice {
  name: 'thankYouNote'
  content: ThankYouContent
}

export interface SpecialConditionContent {
  confirmationQuestion: string
  condition: string
}

export interface SpecialConditionNotice {
  name: 'specialCondition'
  content: SpecialConditionContent
}

type NoticesTypes = ThankYouNotice | SpecialConditionNotice

export interface CustomData {
  colorScheme: ColorScheme
  emailTemplateNamePrefix: string
  logoUrl: string
}

export interface ParkingDetails {
  longDisplayName: string
  email: string
  phone: string
  description: string
  features: string[]
  photos: string[]
  minimalPrice: string
  stepByStepFlows: Step[]
  notices: NoticesTypes[] | null
  digitalParkingCustomizationData: CustomData
}

export interface Plan {
  uuid: string
  name: string
  locizeNameIdentifier?: string
  description: string
  price: string
  period: {
    label: string
    value: number
  }
  promoDescription: string
  startTime?: string
  endTime?: string
  priceObject: {
    amount: number
    currency: string
  }
}

export interface City {
  label?: string
  latitude: number
  longitude: number
  cityUrlSlug?: string
  cityUrlSlugs?: {
    pl: string
    en: string
    de: string
    ar: string
  }
}

export interface AllParkingsResponse {
  items: Parking[]
}

export interface AllParkingsParams {
  query?: string
  lang?: string
  // @format Coma-separated latitude and longitude
  latLon?: string
  radius?: number | ''
  countryCode?: string
  page?: number
  size?: number
  // @example city,asc
  sorting?: string
  subscriptionAvailable?: boolean
  reservationAvailable?: boolean
  infoTag?: string
  search?: string
  visible?: boolean
  category?: string
}

export interface ParkingsBySlugResponse {
  parking: Parking
  details: ParkingDetails
}

export interface ParkingsBySlugParams {
  lang?: string
  countryCode?: string
}

export interface Price {
  amount: number
  currency: string
}

export interface ParkingsPlanResponse {
  plans: Plan[]
  pricePer24Hours: Price
  pricePerHour: Price
}

export interface ParkingsPlanParams {
  lang?: string
  countryCode?: string
}

export interface ParkingsByCityResponse {
  items: Parking[]
}

export interface Categories {
  countryCode?: string
  tag: string
  lang?: string
}

export interface CategoriesResponse {
  items: {
    label: string
    countryCode?: string
    locizeIdentifier?: string
    zoomLevel?: number
    latitude?: number
    longitude?: number
    tags?: string[]
  }[]
}

export interface ParkingsByCityParams {
  query?: string
  lang?: string
  // @format Coma-separated latitude and longitude
  latLon?: string
  radius?: number
  page?: number
  size?: number
  // @example city,asc
  sorting?: string
  subscriptionAvailable?: boolean
  reservationAvailable?: boolean
  infoTag?: string
}

export interface ParkingsSubscriptionPriceResponse {
  discounted: boolean
  price: {
    amount: number
    currency: string
    payableAmount: string
  }
}

export type ParkingsSubscriptionPriceParams =
  | {
      start: string
      end: string
      discountCodeUuid?: string
      lang?: string
    }
  | {
      start: string
      planUuid: string
      discountCodeUuid?: string
      lang?: string
    }

export interface AllCitiesParams {
  lang?: string
  countryCode?: string
}

export interface AllCitiesResponse {
  items: City[]
}

export interface AllParkingsHeaderType {
  'X-Forwarded-For': string
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (axios: AxiosInstance) => ({
  all: (params: AllParkingsParams, headers?: AllParkingsHeaderType) =>
    //@ts-ignore
    axios.get<AllParkingsResponse>('/api/v1/parkings', { params, headers }),
  bySlug: (slug: string, params: ParkingsBySlugParams) =>
    axios.get<ParkingsBySlugResponse>(`/api/v1/parkings/${slug}`, { params }),
  plan: (slug: string, params: ParkingsPlanParams) =>
    axios.get<ParkingsPlanResponse>(`/api/v2/parkings/${slug}/plans`, {
      params
    }),
  cities: (params: AllCitiesParams) =>
    axios.get<AllCitiesResponse>('/api/v1/cities', { params }),
  byCity: (city: string, params: ParkingsByCityParams) =>
    axios.get<ParkingsByCityResponse>(`/api/v1/cities/${city}/parkings`, {
      params
    }),
  subscriptionPrice: (slug: string, params: ParkingsSubscriptionPriceParams) =>
    axios.get<ParkingsSubscriptionPriceResponse>(
      `/api/v2/parkings/${slug}/subscription/price`,
      { params }
    )
})
